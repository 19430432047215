import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
//
import Header from './header';
import Nav from './nav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const token = localStorage.getItem('access_token');

        if (!token) {
          navigate('/login', { replace: true });
        } else {
          const response = await fetch('https://auth.dayspay.net/api/v1/users', {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setUserData(data);
            console.log(data);
          } else {
            console.log(response.body);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getUserData();
  }, []);

  return (
    <div>
      {userData && (
        <StyledRoot>
          <Header onOpenNav={() => setOpen(true)} userData={userData} />

          <Nav openNav={open} onCloseNav={() => setOpen(false)} userData={userData} />
          <Main>
            <Outlet />
          </Main>
        </StyledRoot>
      )}
      {!userData && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-50px',
            marginLeft: '-50px',
            width: '100px',
            height: '100px',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
