import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { Container, Typography } from '@mui/material';
import Button from '@mui/material/Button';

// components
import { OrderList } from '../sections/@dashboard/order';

import Iconify from '../components/iconify';
// ----------------------------------------------------------------------

export default function ApproveOrdersPage() {

  const [page, setPage] = useState(1);
  const [approvedOrders, setApprovedOrders] = useState([]);
  const [isUnapprovel, setIsUnapprovel] = useState(true);
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getOrders = async (page) => {
      setIsloading(true);
      try {
        const token = localStorage.getItem('access_token');

        if (!token) {
          navigate('/login', { replace: true });
        } else {
          let queryToCall;
          if (isUnapprovel){
            queryToCall = 'getUnapprovedOrders';
          } else {
            queryToCall = 'getApprovedOrders';
          }
          const reqBody = `
          query {
            ${queryToCall} (
              pagination: {page: ${page}, items: 20}
            ) {
              data {
                _id
                title
                description
                images
                createdAt
              }
              error {
                code
                message
              }
            }
          }
          `;
          const response = await fetch('https://apisccsa.dayspay.net/graphql', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': '*',
            },
            body: JSON.stringify({
              'query': reqBody,
            }),
          });

          if (response.ok) {
            const data = await response.json();
            console.log(data.data[queryToCall]);
            if (data.data[queryToCall] !== null) {
              const resultArray = data.data[queryToCall].data;
              if (resultArray.length === 0) {
                setIsUnapprovel(false);
                setPage(1);
              }
              const approvedOrdersTmp = [];
              resultArray.forEach((order) => {
                order.isApproved = !isUnapprovel;
                approvedOrdersTmp.push(order);
              });
              setApprovedOrders(approvedOrders.concat(approvedOrdersTmp));
              console.log(approvedOrders);
            } else {
              console.log(data);
            }
          } else {
            console.log(response.body);
          }
          setIsloading(false);
        }
      } catch (error) {
        console.log(error);
        setIsloading(false);
      }
    };

    getOrders(page);
  }, [page]);

  const handleMore = () => {
    if (!isloading) {
      setPage(page+1);
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Orders </title>
      </Helmet>

    
      { approvedOrders ?
        <Container>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Approve Orders
          </Typography>

          <OrderList orders={approvedOrders} />
        </Container>
        : 
        <Iconify icon={'eva:loading-outlined'} />
      }

      <Button onClick={handleMore} style={{position: 'relative',top: '50px', left: '50%', right: '50%', transform: 'translate(-50%, -50%)'}}>
        More
      </Button>
    </>
  );
}
