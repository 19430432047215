import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Divider, Typography } from '@mui/material';
// components
import { OrderList } from '../sections/@dashboard/order';
// mock
import ORDERS from '../_mock/orders';

// ----------------------------------------------------------------------

export default function SelectOrdersPage() {

  return (
    <>
      <Helmet>
        <title> Dashboard: Orders </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
        Featured Orders
        </Typography>

        <OrderList orders={ORDERS.slice(0, 4)} />

        <Divider sx={{ borderStyle: 'dashed' , m: 4}} />

        <Typography variant="h4" sx={{ mb: 5 }}>
          Orders
        </Typography>

        <OrderList orders={ORDERS} />
      </Container>
    </>
  );
}
