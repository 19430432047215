import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// sections
import { AppOrdersStatus, AppWidgetSummary, AppTopUsersByOrder } from '../sections/@dashboard/app';

import Iconify from '../components/iconify';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const [mostActiveUsers, setMostActiveUsers] = useState([]);
  const [ordersStatus, setOrdersStatus] = useState(null);
  const [appSummary, setAppSummary] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getTopUsersByOrders = async () => {
      try {
        const token = localStorage.getItem('access_token');

        if (!token) {
          navigate('/login', { replace: true });
        } else {
          const reqBody = `
          query {
            getTopUsersByOrders {
              data {
                _id
                username
                offers
                orders
              }
              error {
                code
                message
              }
            }
          }
          `;
          const response = await fetch('https://apisccsa.dayspay.net/graphql', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': '*',
            },
            body: JSON.stringify({
              'query': reqBody,
            }),
          });

          if (response.ok) {
            const data = await response.json();
            setMostActiveUsers(data.data.getTopUsersByOrders.data);
            console.log(mostActiveUsers);
          } else {
            console.log(response.body);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getTopUsersByOrders();
  }, []);

  useEffect(() => {
    const getOrdersStatus = async () => {
      try {
        const token = localStorage.getItem('access_token');

        if (!token) {
          navigate('/login', { replace: true });
        } else {
          const reqBody = `
          query {
            getOrdersStatus {
              data {
                active
                disapproved
                completed
                canceled
              }
              error {
                code
                message
              }
            }
          }
          `;
          const response = await fetch('https://apisccsa.dayspay.net/graphql', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': '*',
            },
            body: JSON.stringify({
              'query': reqBody,
            }),
          });

          if (response.ok) {
            const data = await response.json();
            setOrdersStatus(data.data.getOrdersStatus.data);
            console.log(ordersStatus);
          } else {
            console.log(response.body);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getOrdersStatus();
  }, []);

  useEffect(() => {
    const getAppSummary = async () => {
      try {
        const token = localStorage.getItem('access_token');

        if (!token) {
          navigate('/login', { replace: true });
        } else {
          const reqBody = `
          query {
            getAppSummary {
              data {
                verifiedUsers
                activeOffers
                activeOrders
                completedOrders
              }
              error {
                code
                message
              }
            }
          }
          `;
          const response = await fetch('https://apisccsa.dayspay.net/graphql', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': '*',
            },
            body: JSON.stringify({
              'query': reqBody,
            }),
          });

          if (response.ok) {
            const data = await response.json();
            setAppSummary(data.data.getAppSummary.data);
            console.log(appSummary);
          } else {
            console.log(response.body);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAppSummary();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
        { appSummary ?
          <>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Verified Users" total={appSummary.verifiedUsers} icon={'ant-design:user-outlined'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Active Offers" total={appSummary.activeOffers} color="info" icon={'ant-design:profile-outlined'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Active Orders" total={appSummary.activeOrders} color="warning" icon={'ant-design:file-text-outlined'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Completed Orders" total={appSummary.completedOrders} color="success" icon={'ant-design:file-done-outlined'} />
            </Grid>
          </>
          : 
            <Iconify icon={'eva:loading-outlined'} />
          }

          { mostActiveUsers ?
            <Grid item xs={12} md={6} lg={8}>
              {console.log('mostActiveUsers:', mostActiveUsers)}
              <AppTopUsersByOrder
                title="Top Users By Orders"
                chartData={mostActiveUsers.map(user => ({
                  label: user.username,
                  value: user.orders
                }))}
              />
            </Grid> : 
            <Iconify icon={'eva:loading-outlined'} />
          }
          
          { ordersStatus ?
            <Grid item xs={12} md={6} lg={4}>
              <AppOrdersStatus
                title="Orders Status"
                chartData={[
                  { label: 'Active', value: ordersStatus.active },
                  { label: 'Disapproved', value: ordersStatus.disapproved },
                  { label: 'Completed', value: ordersStatus.completed },
                  { label: 'Canceled', value: ordersStatus.canceled },
                ]}
                chartColors={[
                  theme.palette.info.main,
                  theme.palette.primary.main,
                  theme.palette.warning.main,
                  theme.palette.success.main,
                  theme.palette.error.main,
                ]}
              /> 
            </Grid> : 
            <Iconify icon={'eva:loading-outlined'} />
          }
        </Grid>
      </Container>
    </>
  );
}
