import PropTypes from 'prop-types';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import { Box, Card, Link, Typography, Stack, IconButton } from '@mui/material';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { styled } from '@mui/material/styles';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';

import images from '../../../assets';
// ----------------------------------------------------------------------

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="eva:close-fill" color="red" />
      </IconButton>
    </DialogTitle>
  );
}

// ----------------------------------------------------------------------

const StyledOrderImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

OrderCard.propTypes = {
  order: PropTypes.object,
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function OrderCard({ order }) {
  const [approved, setApproved] = useState(order.isApproved);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickApproved = async () => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        navigate('/login', { replace: true });
      } else {
        const reqBody = `
          mutation {
            approveOrder (id: "${order._id}") {
              data {
                success
              }
              error {
                code
                message
              }
            }
          }
          `;
        const response = await fetch('https://apisccsa.dayspay.net/graphql', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
          },
          body: JSON.stringify({
            query: reqBody,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.data.approveOrder !== null && data.data.approveOrder.success) {
            setApproved(true);
            handleClose();
          } else {
            setError(data.data.approveOrder.error.message);
            setOpen(true);
          }
        } else {
          const errorData = await response.json();
          setError(errorData.message);
          setOpen(true);
        }
      }
    } catch (error) {
      setError('An error occurred during login.');
      setOpen(true);
    }
  };

  const handleDisapproved = async () => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        navigate('/login', { replace: true });
      } else {
        const reqBody = `
          mutation {
            unapproveOrder (id: "${order._id}") {
              data {
                success
              }
              error {
                code
                message
              }
            }
          }
          `;
        const response = await fetch('https://apisccsa.dayspay.net/graphql', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
          },
          body: JSON.stringify({
            query: reqBody,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.data.unapproveOrder !== null && data.data.unapproveOrder.success) {
            setApproved(false);
            handleClose();
          } else {
            setError(data.data.unapproveOrder.error.message);
            setOpen(true);
          }
        } else {
          const errorData = await response.json();
          setError(errorData.message);
          setOpen(true);
        }
      }
    } catch (error) {
      setError('An error occurred during login.');
      setOpen(true);
    }
  };

  return (
    <div>
      <Card onClick={handleClickOpen}>
        <Box sx={{ pt: '100%', position: 'relative' }}>
          {approved && (
            <Label
              variant="filled"
              color="info"
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase',
              }}
            >
              approved
            </Label>
          )}
          {order.images && order.images.length > 0 ? (
            <StyledOrderImg alt={order.title} src={order.images[0]} />
          ) : (
            <StyledOrderImg alt={order.title} src={images.loadingImage} />
          )}
        </Box>
        <Stack spacing={2} sx={{ p: 3 }}>
          <Link color="inherit" underline="hover">
            <Typography variant="subtitle2" noWrap>
              {order.title}
            </Typography>
          </Link>
        </Stack>
      </Card>

      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        {order.images && order.images.length > 0 ? (
          <Box sx={{ pt: '35%', position: 'relative' }}>
            <StyledOrderImg alt={order.title} src={order.images[0]} />
          </Box>
        ) : (
          <Box sx={{ pt: '35%', position: 'relative' }}>
            <StyledOrderImg alt={order.title} src={images.loadingImage} />
          </Box>
        )}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {order.title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{order.description}</Typography>
        </DialogContent>
        <DialogActions>
          {approved && (
            <Button onClick={handleDisapproved} color="error">
              Disapprove
            </Button>
          )}
          {!approved && <Button onClick={handleClickApproved}>Approve</Button>}
        </DialogActions>
      </BootstrapDialog>

      {error ? <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>{error}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      : null}
    </div>
  );
}
