import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import OrderCard from './OrderCard';

// ----------------------------------------------------------------------

OrderList.propTypes = {
  orders: PropTypes.array.isRequired,
};

export default function OrderList({ orders, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {orders.map((order) => (
        <Grid key={order.id} item xs={12} sm={6} md={3}>
          <OrderCard order={order} />
        </Grid>
      ))}
    </Grid>
  );
}
